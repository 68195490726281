import { FormEvent, useContext, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Checkbox, DefaultButton, Dialog, FontIcon, Stack, Text } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { ThumbDislike20Filled, ThumbLike20Filled, Check20Regular } from '@fluentui/react-icons'
import DOMPurify from 'dompurify'
import remarkGfm from 'remark-gfm'
import supersub from 'remark-supersub'
import Plot from 'react-plotly.js'
import { AskResponse, Citation, Feedback, historyMessageFeedback } from '../../api'
import { XSSAllowTags, XSSAllowAttributes } from '../../constants/sanatizeAllowables'
import { AppStateContext } from '../../state/AppProvider'

import { parseAnswer } from './AnswerParser'

import styles from './Answer.module.css'

let currentStep = 1;

interface Props {
  answer: AskResponse
  onCitationClicked: (citedDocument: Citation) => void
  onExectResultClicked: () => void
  onSend: (question: string, id?: string) => void
  conversationId?: string
}

export const Answer = ({ answer, onCitationClicked, onExectResultClicked, onSend, conversationId }: Props) => {
        const [disablledAnswersIds, setDisablledAnswersIds] = useState<any[]>([]);

  const initializeAnswerFeedback = (answer: AskResponse) => {
 
    if (answer.message_id == undefined) return undefined
    if (answer.feedback == undefined) return undefined
    if (answer.feedback.split(',').length > 1) return Feedback.Negative
    if (Object.values(Feedback).includes(answer.feedback)) return answer.feedback
    return Feedback.Neutral
  }

  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false)
  const filePathTruncationLimit = 50
  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer])
  const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen)
  const [feedbackState, setFeedbackState] = useState(initializeAnswerFeedback(answer))
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false)
  const [showReportInappropriateFeedback, setShowReportInappropriateFeedback] = useState(false)
    const [copySuccess, setCopySuccess] = useState<string>('');
      const [buttonText, setButtonText] = useState<string>('Kopiëren');

  const [negativeFeedbackList, setNegativeFeedbackList] = useState<Feedback[]>([])
  const appStateContext = useContext(AppStateContext)
  const FEEDBACK_ENABLED =
    appStateContext?.state.frontendSettings?.feedback_enabled && appStateContext?.state.isCosmosDBAvailable?.cosmosDB
  const SANITIZE_ANSWER = appStateContext?.state.frontendSettings?.sanitize_answer

const disableAllAnswers = (answerId: string | undefined) => {
   setDisablledAnswersIds([...disablledAnswersIds,answerId])
console.log([...disablledAnswersIds,answerId])
  }

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded)
    toggleIsRefAccordionOpen()
  }

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen)
  }, [isRefAccordionOpen])
//  useEffect(() => {
//     let timer: NodeJS.Timeout;
//     if (copySuccess) {
//       timer = setTimeout(() => {
//         setCopySuccess('');
//         setButtonText('Kopiëren'); // Revert button text back after 3 seconds

//       }, 1500); // Hide message after 3 seconds
//     }
//     return () => clearTimeout(timer); // Clear timeout if component unmounts or copySuccess changes
//   }, [copySuccess]);

  useEffect(() => {
    if (answer.message_id == undefined) return

    let currentFeedbackState
    if (appStateContext?.state.feedbackState && appStateContext?.state.feedbackState[answer.message_id]) {
      currentFeedbackState = appStateContext?.state.feedbackState[answer.message_id]
    } else {
      currentFeedbackState = initializeAnswerFeedback(answer)
    }
    setFeedbackState(currentFeedbackState)
  }, [appStateContext?.state.feedbackState, feedbackState, answer.message_id])

  const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
    let citationFilename = ''

    if (citation.filepath) {
      const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '')
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length
        citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${part_i}`
      } else {
        citationFilename = `${citation.filepath} - Part ${part_i}`
      }
    } else if (citation.filepath && citation.reindex_id) {
      citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`
    } else {
      citationFilename = `Citation ${index}`
    }
    return citationFilename
  }

  const onLikeResponseClicked = async () => {
    if (answer.message_id == undefined) return

    let newFeedbackState = feedbackState
    // Set or unset the thumbs up state
    if (feedbackState == Feedback.Positive) {
      newFeedbackState = Feedback.Neutral
    } else {
      newFeedbackState = Feedback.Positive
    }
    appStateContext?.dispatch({
      type: 'SET_FEEDBACK_STATE',
      payload: { answerId: answer.message_id, feedback: newFeedbackState }
    })
    setFeedbackState(newFeedbackState)

    // Update message feedback in db
    await historyMessageFeedback(answer.message_id, newFeedbackState)
  }

  const onDislikeResponseClicked = async () => {
    if (answer.message_id == undefined) return

    let newFeedbackState = feedbackState
    if (feedbackState === undefined || feedbackState === Feedback.Neutral || feedbackState === Feedback.Positive) {
      newFeedbackState = Feedback.Negative
      setFeedbackState(newFeedbackState)
      setIsFeedbackDialogOpen(true)
    } else {
      // Reset negative feedback to neutral
      newFeedbackState = Feedback.Neutral
      setFeedbackState(newFeedbackState)
      await historyMessageFeedback(answer.message_id, Feedback.Neutral)
    }
    appStateContext?.dispatch({
      type: 'SET_FEEDBACK_STATE',
      payload: { answerId: answer.message_id, feedback: newFeedbackState }
    })
  }

  const updateFeedbackList = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (answer.message_id == undefined) return
    const selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback

    let feedbackList = negativeFeedbackList.slice()
    if (checked) {
      feedbackList.push(selectedFeedback)
    } else {
      feedbackList = feedbackList.filter(f => f !== selectedFeedback)
    }

    setNegativeFeedbackList(feedbackList)
  }

  const onSubmitNegativeFeedback = async () => {
    if (answer.message_id == undefined) return
    await historyMessageFeedback(answer.message_id, negativeFeedbackList.join(','))
    resetFeedbackDialog()
  }

  const resetFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
    setShowReportInappropriateFeedback(false)
    setNegativeFeedbackList([])
  }

  const UnhelpfulFeedbackContent = () => {
    return (
      <>
        <div>Why wasn't this response helpful?</div>
        <Stack tokens={{ childrenGap: 4 }}>
          <Checkbox
            label="Citations are missing"
            id={Feedback.MissingCitation}
            defaultChecked={negativeFeedbackList.includes(Feedback.MissingCitation)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Citations are wrong"
            id={Feedback.WrongCitation}
            defaultChecked={negativeFeedbackList.includes(Feedback.WrongCitation)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="The response is not from my data"
            id={Feedback.OutOfScope}
            defaultChecked={negativeFeedbackList.includes(Feedback.OutOfScope)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Inaccurate or irrelevant"
            id={Feedback.InaccurateOrIrrelevant}
            defaultChecked={negativeFeedbackList.includes(Feedback.InaccurateOrIrrelevant)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Other"
            id={Feedback.OtherUnhelpful}
            defaultChecked={negativeFeedbackList.includes(Feedback.OtherUnhelpful)}
            onChange={updateFeedbackList}></Checkbox>
        </Stack>
        <div onClick={() => setShowReportInappropriateFeedback(true)} style={{ color: '#115EA3', cursor: 'pointer' }}>
          Report inappropriate content
        </div>
      </>
    )
  }

  const ReportInappropriateFeedbackContent = () => {
    return (
      <>
        <div>
          The content is <span style={{ color: 'red' }}>*</span>
        </div>
        <Stack tokens={{ childrenGap: 4 }}>
          <Checkbox
            label="Hate speech, stereotyping, demeaning"
            id={Feedback.HateSpeech}
            defaultChecked={negativeFeedbackList.includes(Feedback.HateSpeech)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Violent: glorification of violence, self-harm"
            id={Feedback.Violent}
            defaultChecked={negativeFeedbackList.includes(Feedback.Violent)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Sexual: explicit content, grooming"
            id={Feedback.Sexual}
            defaultChecked={negativeFeedbackList.includes(Feedback.Sexual)}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Manipulative: devious, emotional, pushy, bullying"
            defaultChecked={negativeFeedbackList.includes(Feedback.Manipulative)}
            id={Feedback.Manipulative}
            onChange={updateFeedbackList}></Checkbox>
          <Checkbox
            label="Other"
            id={Feedback.OtherHarmful}
            defaultChecked={negativeFeedbackList.includes(Feedback.OtherHarmful)}
            onChange={updateFeedbackList}></Checkbox>
        </Stack>
      </>
    )
  }

  const components = {
    code({ node, ...props }: { node: any;[key: string]: any }) {
      let language
      if (props.className) {
        const match = props.className.match(/language-(\w+)/)
        language = match ? match[1] : undefined
      }
      const codeString = node.children[0].value ?? ''
      return (
        <SyntaxHighlighter style={nord} language={language} PreTag="div" {...props}>
          {codeString}
        </SyntaxHighlighter>
      )
    }
  }

function filterMedicalTerms(InputMarkdownFormatText: any): any {
  const medial_terms = ["rugpijn", "hoofdpijn", "vermoeidheid", "burn-out", "depressie", "stress", 
        "angststoornissen", "slaapproblemen", "allergieën", "zenuwpijn", "tinnitus", "burnout", "burn out"]

  const intervention_terms = ["decongestivum", "diureticum", "emeticum", "expectorans", "laxans", "oxytocica", "profylaxe", "purgatief", "scabicide", "sublinguaal", "tomografie", "tranquillizer", "vaccin", "vasodilitatie"]

  const other_terms = ["causticus", "cerebellair", "cholesterol", "cochleair", "conjugatus", "empirisch", "gluten", "gonadaal"]

  const medical_replacement = "START REPLACE medische beperking END REPLACED"
  const intervention_replacement = "START REPLACE medische interventie END REPLACED"
  const other_replacement = "START REPLACE *** END REPLACED"

  medial_terms.forEach(term => {
    const regex = new RegExp(`\\b${term}\\b`, 'gi') 
    InputMarkdownFormatText = InputMarkdownFormatText.replace(regex, medical_replacement)
  })

  intervention_terms.forEach(term => {
    const regex = new RegExp(`\\b${term}\\b`, 'gi') 
    InputMarkdownFormatText = InputMarkdownFormatText.replace(regex, intervention_replacement)
  })

  other_terms.forEach(term => {
    const regex = new RegExp(`\\b${term}\\b`, 'gi') 
    InputMarkdownFormatText = InputMarkdownFormatText.replace(regex, other_replacement)
  })

  return InputMarkdownFormatText
}


  return (
    <>
      <Stack style={{ display: "flex", flexDirection: "column", gap: "5" }}>
      
      
        <Stack className={styles.answerContainer} tabIndex={0}>
           {parsedAnswer.markdownFormatText !== null && parsedAnswer.markdownFormatText!== "Aan het nadenken..." && (
             
        <button className={styles.copyButton } onClick={() => {

      //  if ( parsedAnswer.markdownFormatText.includes("6.3 Welke arbeidsmogelijkheden heeft de werknemer nog wel?") || 
      //      parsedAnswer.markdownFormatText.includes("A. Arbeidsgeschiktheid:" )  
      //     ) {
      //          parsedAnswer.markdownFormatText = filterMedicalTerms(parsedAnswer.markdownFormatText);
      //     } 

       if (parsedAnswer.markdownFormatText) {

      const plainAnswer = parsedAnswer.markdownFormatText.replace(/\*\*|^#{3,4}\s?/gm, '');

      navigator.clipboard.writeText(plainAnswer).then(() => {
        setButtonText('Gekopieerd!');
        setCopySuccess('tekst gekopieerd naar clipboard!');

      }).catch(err => {
        console.error('Could not copy text: ', err);
        setButtonText('Failed to copy text.');
        setCopySuccess('Failed to copy text.');
      });
    } else {
      setButtonText('No text');
      setCopySuccess('No text to copy!');

    }
  }
  }>
   {buttonText === "tekst gekopieerd!" ? <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>:  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" >
    <path fill="currentColor" fillRule="evenodd" d="M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z" clipRule="evenodd"></path>
    </svg>} 
    {buttonText}</button>
          )}
        <Stack.Item>
            <Stack horizontal grow>
              <Stack.Item grow>
                <ReactMarkdown
                  linkTarget="_blank"
                  remarkPlugins={[remarkGfm, supersub]}
                  children={
                    SANITIZE_ANSWER
                      ? DOMPurify.sanitize(parsedAnswer.markdownFormatText, { ALLOWED_TAGS: XSSAllowTags, ALLOWED_ATTR: XSSAllowAttributes })
                      : parsedAnswer.markdownFormatText
                  }
                  className={styles.answerText}
                  components={components}
                />
              </Stack.Item>
              <Stack.Item className={styles.answerHeader}>
                {FEEDBACK_ENABLED && answer.message_id !== undefined && (
                  <Stack horizontal horizontalAlign="space-between">
                    <ThumbLike20Filled
                      aria-hidden="false"
                      aria-label="Like this response"
                      onClick={() => onLikeResponseClicked()}
                      style={
                        feedbackState === Feedback.Positive ||
                          appStateContext?.state.feedbackState[answer.message_id] === Feedback.Positive
                          ? { color: 'darkgreen', cursor: 'pointer' }
                          : { color: 'slategray', cursor: 'pointer' }
                      }
                    />
                    <ThumbDislike20Filled
                      aria-hidden="false"
                      aria-label="Dislike this response"
                      onClick={() => onDislikeResponseClicked()}
                      style={
                        feedbackState !== Feedback.Positive &&
                          feedbackState !== Feedback.Neutral &&
                          feedbackState !== undefined
                          ? { color: 'darkred', cursor: 'pointer' }
                          : { color: 'slategray', cursor: 'pointer' }
                      }
                    />
                  </Stack>
                )}
              </Stack.Item>
            </Stack>
            <Stack horizontal className={styles.ChatHistoryList}>
     
            </Stack>
          </Stack.Item>
          <Stack horizontal className={styles.ChatHistoryList}>
     
          </Stack>
          {parsedAnswer.plotly_data !== null && (
            <Stack className={styles.answerContainer}>
              <Stack.Item grow>
                <Plot data={parsedAnswer.plotly_data.data} layout={parsedAnswer.plotly_data.layout} />
              </Stack.Item>
            </Stack>
          )}
          
          {/* <Stack horizontal className={styles.answerFooter}>
            {!!parsedAnswer.citations.length && (
              <Stack.Item onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? toggleIsRefAccordionOpen() : null)}>
                <Stack style={{ width: '100%' }}>
                  <Stack horizontal horizontalAlign="start" verticalAlign="center">
                    <Text
                      className={styles.accordionTitle}
                      onClick={toggleIsRefAccordionOpen}
                      aria-label="Open references"
                      tabIndex={0}
                      role="button">
                      <span>
                        {parsedAnswer.citations.length > 1
                          ? parsedAnswer.citations.length + ' references'
                          : '1 reference'}
                      </span>
                    </Text>
                    <FontIcon
                      className={styles.accordionIcon}
                      onClick={handleChevronClick}
                      iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                    />
                  </Stack>
                </Stack>
              </Stack.Item>
            )}
            <Stack.Item className={styles.answerDisclaimerContainer}>
              <span className={styles.answerDisclaimer}></span>
            </Stack.Item>
           
            {!!answer.exec_results?.length && (
              <Stack.Item onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? toggleIsRefAccordionOpen() : null)}>
                <Stack style={{ width: '100%' }}>
                  <Stack horizontal horizontalAlign="start" verticalAlign="center">
                    <Text
                      className={styles.accordionTitle}
                      onClick={() => onExectResultClicked()}
                      aria-label="Open Intents"
                      tabIndex={0}
                      role="button">
                      <span>
                        Show Intents
                      </span>
                    </Text>
                    <FontIcon
                      className={styles.accordionIcon}
                      onClick={handleChevronClick}
                      iconName={'ChevronRight'}
                    />
                  </Stack>
                </Stack>
              </Stack.Item>
            )}
          </Stack> */}
    
          {chevronIsExpanded && (
            <div className={styles.citationWrapper}>
              {parsedAnswer.citations.map((citation, idx) => {
                return (
                  <span
                    title={createCitationFilepath(citation, ++idx)}
                    tabIndex={0}
                    role="link"
                    key={idx}
                    onClick={() => onCitationClicked(citation)}
                    onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onCitationClicked(citation) : null)}
                    className={styles.citationContainer}
                    aria-label={createCitationFilepath(citation, idx)}>
                    <div className={styles.citation}>{idx}</div>
                    {createCitationFilepath(citation, idx, true)}
                  </span>
                )
              })}
            </div>
          )}
        </Stack>

        {/* beginning of staap 2 */}
        {( answer?.answer?.includes("eerste consult") && answer?.answer?.includes("vervolgconsult") )&& (
          <Stack horizontal style={{gap:5}}>
          <div className={styles.chatMessageUserMessage}
          style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}         
               onClick={() => { 
              if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Eerste consult", conversationId)
                disableAllAnswers(answer.message_id)
              } else {
                onSend("Eerste consult", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Eerste consult</div>
            <div className={styles.chatMessageUserMessage}
            style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            
              if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Vervolgconsult", conversationId)
                disableAllAnswers(answer.message_id)

              } else {
                onSend("Vervolgconsult", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Vervolgconsult</div>
        </Stack>
        )}

        {/* end of staap 3 */}
        {( answer?.answer?.includes("A. Anamnese") && answer?.answer?.includes("D. Advies bedrijfsarts") )&& (
          <Stack horizontal style={{gap:5}}>
          <div className={styles.chatMessageUserMessage}
          style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}         
               onClick={() => { 
              if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Wijzigen", conversationId)
                disableAllAnswers(answer.message_id)
              } else {
                onSend("Wijzigen", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Wijzigen</div>
            <div className={styles.chatMessageUserMessage}
            style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            
              if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Alles klopt", conversationId)
                disableAllAnswers(answer.message_id)

              } else {
                onSend("Alles klopt", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Alles klopt</div>

            <div className={styles.chatMessageUserMessage}
            style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            
              if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Gebruik meer details uit het gesprek", conversationId)
                disableAllAnswers(answer.message_id)

              } else {
                onSend("Gebruik meer details uit het gesprek", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Gebruik meer details uit het gesprek</div>

        </Stack>
        )}


        {/* beginning of staap 4 */}
        {( answer?.answer?.includes("probleemanalyse") && answer?.answer?.includes("terugkoppeling re-integratieadvies") )&& (
          <Stack horizontal style={{gap:5}}>
          <div className={styles.chatMessageUserMessage}
   style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Probleemanalyse", conversationId)
                disableAllAnswers(answer.message_id)

              } else {
                onSend("Probleemanalyse", conversationId)
                disableAllAnswers(answer.message_id)

              }
            }} >
            Probleemanalyse</div>
            <div className={styles.chatMessageUserMessage}
   style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Terugkoppeling re-integratieadvies", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Terugkoppeling re-integratieadvies", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Terugkoppeling re-integratieadvies</div>
        </Stack>
        )}

        {/* end of staap 4 */}
        {(
           answer?.answer?.includes("6.3 Benutbare mogelijkheden") || 
          answer?.answer?.includes("A. Arbeidsgeschiktheid" )  
         ) && (
          <Stack horizontal style={{gap:5}}>
          <div className={styles.chatMessageUserMessage}
            style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
            onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Wijzigen", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Wijzigen", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Wijzigen</div>

            <div className={styles.chatMessageUserMessage}

        style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Doe een extra privacy check", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Doe een extra privacy check", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Doe een extra privacy check</div>      

            
            <div className={styles.chatMessageUserMessage}
        style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Omschrijf de beperkingen in CBBS. Let op! Ga uit van de rubrieken zoals omschreven in de lijst met normaalwaarden in het CBBS. Geef een omschrijving van de beperking per rubriek.", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Omschrijf de beperkingen in CBBS. Let op! Ga uit van de rubrieken zoals omschreven in de lijst met normaalwaarden in het CBBS. Geef een omschrijving van de beperking per rubriek.", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Omschrijf de beperkingen in CBBS</div>     

            <div className={styles.chatMessageUserMessage}

        style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Alles klopt", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Alles klopt", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Alles klopt</div>

        </Stack>
        )}

        {/* end of staap 5 */}
        {( answer?.answer?.includes("casemanager") && answer?.answer?.includes("werkgever") && answer?.answer?.includes("beantwoord") )&& (
          <Stack horizontal style={{gap:5}}>
          <div className={styles.chatMessageUserMessage}
   style={{ cursor: !disablledAnswersIds.includes(answer.message_id)? "pointer": "no-drop",
            background:  !disablledAnswersIds.includes(answer.message_id)? "#0078A6" : "#ddd",}}
                        onClick={() => {
                            if(!disablledAnswersIds.includes(answer.message_id))
              if (conversationId) {
                onSend("Vragen zijn beantwoord, ga door", conversationId)
                                disableAllAnswers(answer.message_id)

              } else {
                onSend("Vragen zijn beantwoord, ga door", conversationId)
                                disableAllAnswers(answer.message_id)

              }
            }} >
            Vragen zijn beantwoord, ga door</div>
        </Stack>
        )}


      </Stack>
      <Stack horizontal className={styles.ChatHistoryList}>
 
      </Stack>


      <Dialog
        onDismiss={() => {
          resetFeedbackDialog()
          setFeedbackState(Feedback.Neutral)
        }}
        hidden={!isFeedbackDialogOpen}
        styles={{
          main: [
            {
              selectors: {
                ['@media (min-width: 480px)']: {
                  maxWidth: '600px',
                  background: '#FFFFFF',
                  boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  maxHeight: '600px',
                  minHeight: '100px'
                }
              }
            }
          ]
        }}
        dialogContentProps={{
          title: 'Submit Feedback',
          showCloseButton: true
        }}>
        <Stack tokens={{ childrenGap: 4 }}>
          <div>Your feedback will improve this experience.</div>

          {!showReportInappropriateFeedback ? <UnhelpfulFeedbackContent /> : <ReportInappropriateFeedbackContent />}

          <div>By pressing submit, your feedback will be visible to the application owner.</div>

          <DefaultButton disabled={negativeFeedbackList.length < 1} onClick={onSubmitNegativeFeedback}>
            Submit
          </DefaultButton>
        </Stack>
      </Dialog>
    </>
  )
}
